import React from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../LayoutWrapper/AdminLayout";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <AdminLayout>
      <section className="privacy-wrapper py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="page-title text-center">
                {t("page_title_privacy_policy")}
              </h1>

             
              <div className="comfort-body-content py-md-5 py-3">
                <p className="page-para">
                  This privacy policy would explain how we will collect,
                  process, use, share, and protect the information that you
                  share. It will also tell how you can access and update the
                  information and make choices about the same.{" "}
                </p>
                <p className="page-para">
                  This privacy policy would cover online (website, mobile
                  application) and offline (data collected through telephone,
                  mailings, or in person) activities provided, operated, or
                  owned by the Company. These "online" and "offline activities
                  are related to the facilitation of taxi hire services through
                  taxi operators and third-party drivers, which we will refer to
                  as the "Services."
                </p>
                <p className="page-para">
                  The Privacy policy would also apply to your using interactive
                  features or downloads that we own or control, are available
                  through services, and interact with the services.
                </p>
                <p className="page-para">
                  By Accepting The Customer Terms And Conditions, You Agree To
                  The Terms Of This Privacy Policy.{" "}
                </p>
                <p className="page-para">
                  If you have any questions about the Privacy Policy, you can
                  contact us through the email address on the website or through
                  the Mobile application.
                </p>
                <h2 className="point-title text-capitalize mt-4 mb-3">
                  What Information Will We Collect?
                </h2>
                <h2 className="point-title text-capitalize mt-4 mb-3">
                  Information You Provide To Us
                </h2>
                <p className="page-para">
                  We will ask you to provide us with specific protected data.
                  This information we will collect through modes like account
                  registration forms, contact us forms, or any other forms. When
                  you sign up on the app, you will have to create a user
                  profile. The data that we will ask for will include the
                  following:
                </p>
                <p className="page-para">Full name</p>
                <p className="page-para">Email</p>
                <p className="page-para">Password</p>
                <p className="page-para">Address</p>
                <p className="page-para">Mobile phone Number</p>
                <p className="page-para">Pin code </p>
                <h2 className="point-title text-capitalize mt-4 mb-3">
                  Information We Collect As You Access And Use Our Services
                </h2>
                <p className="page-para">
                  In addition to this information, we would use technologies
                  that would collect data whenever you visit or interact with
                  the Services. This could include your Browser, the URL you
                  used to reach our services, areas of services you accessed,
                  time of day, etc. We will also find the Device Identifier,
                  which is a number that is assigned to the device you are
                  using. In case of booking via the call center, Ola may record
                  calls and chat histories for quality and training purposes.
                </p>
                <p className="page-para">
                  If you book a vehicle through …………, we will also decode the
                  following details: <br />• Tracking information as you use our
                  services <br />• Details of emergency contact name and phone
                  number <br />• Number of friends that you refer to our app{" "}
                  <br />• Contact information of any other person for whom you
                  booked the ride
                </p>
                <p className="page-para">
                  We will collect usage information through Cookies. If you
                  don't want that information to be shared, you can deny the use
                  of cookies. However, if you choose to do so, some features of
                  the service may not be available.{" "}
                </p>
                <h2 className="point-title text-capitalize mt-4 mb-3">
                  Information Collected By Mobile Applications
                </h2>
                <p className="page-para">
                  When you use your Mobile application, it may automatically
                  collect the following information:
                </p>
                <p className="page-para">Your preferred language </p>
                <p className="page-para">Country site</p>
                <p className="page-para">
                  The manufacturer and model of your mobile device
                </p>
                <p className="page-para">Your mobile operating system</p>
                <p className="page-para">
                  The type of mobile internet browsers you are using
                </p>
                <p className="page-para">Your geolocation</p>
                <p className="page-para">
                  Information about how you interact with the mobile application
                  and the duration
                </p>
                <p className="page-para">
                  Data from the SMS/ text messages for issuing OTP, filling
                  information during financial transactions, etc.{" "}
                </p>
                <h2 className="point-title text-capitalize mt-4 mb-3">
                  How And When Do We Disclose Information To Third Parties
                </h2>
                <p className="page-para">
                  We will not sell, share or trade any information that we have
                  received about you other than what we are disclosing in this
                  Privacy Policy. These are the instances when you might get
                  contacted by third parties.
                </p>
                <p className="page-para">
                  • If you agree to have, your information shared but this will
                  be subject to the Privacy policy terms. <br />• To those who
                  are facilitating our services, like drivers and companies they
                  are working for. <br />• Third parties that track services
                  analytics, process payments, and engage in anti-fraud or
                  security measures. <br />• Those who provide customer support,
                  geolocation information, perform technical services, etc.
                </p>
                <h2 className="point-title text-capitalize mt-4 mb-3">
                  Contests And Promotions
                </h2>
                <p className="page-para">
                  We may offer promotions that would need registrations. When
                  you participate in the promotion, you agree to the rules that
                  govern that promotion. This includes allowing the sponsor of
                  the promotion to use your name, voice, etc., in the
                  advertising and marketing of the promotion. By entering the
                  promotion, you agree that the information be disclosed to
                  third parties to help in the administration of the promotion.
                  This includes prize fulfillment, winner selection, etc.
                </p>
                <h2 className="point-title text-capitalize mt-4 mb-3">
                  Business Transfer
                </h2>
                <p className="page-para">
                  We may share your data with our parent, subsidiaries, and
                  affiliates for internal reasons or business and promotional
                  purposes. We will also reserve the right to transfer and
                  disclosing of the information to the following: <br />
                  <br />• To an owner, co-owner, or operator of Services or
                  database <br />• In relation to a corporate merger,
                  restructuring, consolidation, sale of membership interests or
                  assets, or any other corporate range
                </p>
                <h2 className="point-title text-capitalize mt-4 mb-3">
                  Market Study And Other Benefits
                </h2>
                <p className="page-para">
                  We may share the data with third parties that may include
                  market research/study, conduct data analysis, find out and
                  customize product or service offerings, improve products and
                  services, make any other product available to you, etc.{" "}
                </p>
                <h2 className="point-title text-capitalize mt-4 mb-3">
                  Third-party Content And Links To Third-party Services
                </h2>
                <p className="page-para">
                  The services could contain content supplied by a third party.
                  These third parties may contain website usage information and
                  the Device Identifier. Also, during the use of services, you
                  may be directed to other sites and applications that could be
                  controlled by them. We will have no say in that matter.
                </p>
                <p className="page-para">
                  We will not be responsible for the privacy practices that
                  these third parties use. These parties may send their own
                  cookies, collect data or solicit your protected information.
                  They may not even have their own published privacy policies.
                  We would advise that when you leave our services, you must
                  read the Privacy policies of these third-party websites or
                  applications.{" "}
                </p>
                <h2 className="point-title text-capitalize mt-4 mb-3">
                  Social Media Features And Widgets
                </h2>
                <p className="page-para">
                  The online and mobile services may also include social media
                  features like the Facebook Like button or the “Share This”
                  button. They may also include interactive mini-programs that
                  run on online and mobile services. The features could collect
                  IP addresses, photographs, and pages you are visiting and set
                  a cookie to ensure the feature works properly. All these
                  features would come under the Privacy policy set by the
                  providers.{" "}
                </p>
                <h2 className="point-title text-capitalize mt-4 mb-3">
                  Information Collected By Drivers
                </h2>
                <p className="page-para">
                  This Privacy Policy will not cover the use of information that
                  is obtained by the driver or his Company. This could be while
                  providing you a ride on a cab booked using our services or not
                  provided by us.
                </p>
                <h2 className="point-title text-capitalize mt-4 mb-3">
                  Change Of Information And Cancellation Of Account
                </h2>
                <p className="page-para">
                  You are liable to maintain the accuracy of the data you submit
                  to us. If your protected information has changed or you do not
                  need our services, you could correct, or remove the mistakes,
                  change the information by going to our member information
                  page, or you could contact us through the email address
                  mentioned on the website or mobile application.
                </p>
                <p className="page-para">
                  You could also cancel or change the communications that you
                  chose to receive from the services. This you can do by
                  following the instructions mentioned in the email. Or you
                  could log into your user account and change the communication
                  preferences.
                </p>
                <p className="page-para">
                  If you want to cancel your account, you can contact us through
                  the email address that is mentioned on the trip bill received.
                  We retain your protected information only until your account
                  with our Services is active. After your account is canceled,
                  we will still retain the Protected and Usage information for
                  the following purposes: <br />
                  <br />• Legal and regulatory obligations <br />• Resolving
                  disputes • Complete activities related to cancellation <br />•
                  Investigate any fraud or inappropriate activity <br />•
                  Enforce the agreements <br />• Data analysis
                </p>
                <h2 className="point-title text-capitalize mt-4 mb-3">
                  Security
                </h2>
                <p className="page-para">
                  The Protected information and usage information we have
                  collected will be securely stored within our database. We use
                  reasonable security practices like encryption, firewalls, and
                  SSL to protect the information. However, no security system is
                  impenetrable. We will not be able to guarantee the security of
                  the databases and promise that your information will remain
                  absolutely safe. It is advisable that you do not share your
                  password with anyone.
                </p>
                <h2 className="point-title text-capitalize mt-4 mb-3">
                  Grievance Officer
                </h2>
                <p className="page-para">
                  We appoint our support manager as the grievance officer, and
                  he can be contacted at{" "}
                </p>
                <h2 className="point-title text-capitalize mt-4 mb-3">
                  Changes To The Privacy Policy
                </h2>

                <p className="page-para">
                  With time we might update the Privacy Policy to show the
                  changes to the information services. The changes made will be
                  effective once we post the revised Privacy Policy.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AdminLayout>
  );
};

export default PrivacyPolicy;
