import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// const Homepage = lazy(() => import("../Pages/Homepage"));
// const PrivacyPolicy = lazy(() => import("../Pages/PrivacyPolicy"));
// const AboutUs = lazy(() => import("../Pages/AboutUs"));
// const Contact = lazy(() => import("../Pages/Contact"));
// const UserLogin = lazy(() => import("../Pages/UserLogin"));
// const UserSignup = lazy(() => import("../Pages/UserSignup"));
// const DriverSignup = lazy(() => import("../Pages/DriverSignup"));
// const DriverLogin = lazy(() => import("../Pages/DriverLogin"));
// const Safety = lazy(() => import("../Pages/Safety"));
// const TermsConditions = lazy(() => import("../Pages/Terms-Conditions"));
// const CookiesPolicy = lazy(() => import("../Pages/Cookies-Policy"));
// const HelpSupport = lazy(() => import("../Pages/Help-Support"));
// const ResetPassword = lazy(() => import("../Pages/ResetPassword"));
// const Blog = lazy(() => import("../Pages/Blog"));
// const ScrollTop = lazy(() => import("../Components/ScrollToTop"));
import Homepage from "../Pages/Homepage";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import AboutUs from "../Pages/AboutUs";
import Contact from "../Pages/Contact";
import UserLogin from "../Pages/UserLogin";
import UserSignup from "../Pages/UserSignup";
import DriverSignup from "../Pages/DriverSignup";
import DriverLogin from "../Pages/DriverLogin";
import Safety from "../Pages/Safety";
import TermsConditions from "../Pages/Terms-Conditions";
import CookiesPolicy from "../Pages/Cookies-Policy";
import HelpSupport from "../Pages/Help-Support";
import ResetPassword from "../Pages/ResetPassword";
import Blog from "../Pages/Blog";
import ScrollTop from "../Components/ScrollToTop";
import DriverResetPassword from "../Pages/ResetPasswordDriver";
import UpdatePasswordUser from "../Pages/UpdatePasswordUser";
import OTPInput from "../Pages/Otp";
import UpdatePasswordDriver from "../Pages/UpdatePasswordDriver";
import DeleteAccount from "../Pages/DeleteData";
import DeleteAccountPermanent from "../Pages/DeleteAccount";

const RoutesWrapper = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollTop>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/user-signup" element={<UserSignup />} />
            <Route path="/user-login" element={<UserLogin />} />
            <Route path="/driver-signup" element={<DriverSignup />} />
            <Route path="/driver-login" element={<DriverLogin />} />
            <Route path="/safety" element={<Safety />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/cookies-policy" element={<CookiesPolicy />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/help-support" element={<HelpSupport />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/driver-reset-password" element={<DriverResetPassword />} />
            <Route path="/update-password-user/:email" element={<UpdatePasswordUser />} />
            <Route path="/update-password-driver/:email" element={<UpdatePasswordDriver />} />
            <Route path="/otp" element={<OTPInput />} />
            <Route path="/delete-data" element={<DeleteAccount />} />
            <Route path="/delete-account" element={<DeleteAccountPermanent />} />
          </Routes>
        </ScrollTop>
      </BrowserRouter>
    </>
  );
};

export default RoutesWrapper;
