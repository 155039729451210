import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import AdminLayout from "../../LayoutWrapper/AdminLayout";
import "../../CSS/UserSignup/index.css";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import { postApihandler } from "../../ApiHandler";
import { useForm } from "react-hook-form";

const UserLogin = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const [validated, setValidated] = useState(false);
  const { register, handleSubmit, reset, setValue, watch } = useForm();

  const SubmitLogins = async (value) => {
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }

    // setValidated(true);
    let result = await postApihandler("/userLogin", value);
    console.log("response - ",result);
    if (result.status == 200) {
      localStorage.setItem("accessToken", result.data.accessToken);
      localStorage.setItem("userData",JSON.stringify(result.data.data));
      swal({
        icon: "success",
        text: "You Have Successfully Logined",
      });
       history("/");
    } else {
      swal({
        icon: "error",
        title: "Please Try Again",
        text: result.error.response.data.message,
      });
      console.log("false result is - ", result.error.response.data.message);
    }
  };
  return (
    <AdminLayout>
      <section className="signupFormWrapper d-flex mb-5 flex-lg-row flex-column">
        <div className="form-wrapper-column-1">
          <div className="imgWrapper bgImg h-100"></div>
        </div>
        <div className="form-Data-Wrapper form-wrapper-column-2 py-4 px-5">
          <h3 className="signupTitle mb-4">{t("page_title_userLogin")}</h3>
          <div className="form-field-wrapper">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit(SubmitLogins)}
            >
              <Row className="mb-4">
                <Form.Group as={Col} controlId="validationCustomUsername">
                  <InputGroup hasValidation>
                    <Form.Control
                      type="email"
                      placeholder={t("form_email_text")}
                      aria-describedby="inputGroupPrepend"
                      required
                      {...register("email")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please fill a email.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-4">
                <Form.Group as={Col} controlId="validationCustom03">
                  <Form.Control
                    type="password"
                    placeholder={t("form_password_text")}
                    required
                    {...register("password")}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please fill a valid password.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Form.Group className="mb-4 text-md-end">
                <Link to="/reset-password">
                  <label className="forget-pswd-text">
                    {t("form_forgotPass")}
                  </label>
                </Link>
              </Form.Group>
              <Form.Group className="text-center mb-4">
                <Button type="submit">{t("form_loginBtn_text")}</Button>
              </Form.Group>
              <Form.Group className="text-center">
                <p className="paragraph text-md-start">
                  {t("form_dont_have_account")}
                  <Link to="/user-signup">
                    <span className="blue-text">
                      {t("form_signupBtn_text")}
                    </span>
                  </Link>
                </p>
              </Form.Group>
            </Form>
          </div>
        </div>
      </section>
    </AdminLayout>
  );
};

export default UserLogin;
