import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../Header";
import Footer from "../Footer";

export default function LayoutTemplate({ children }) {
  return (
    <div>
      <Header />
      <section>{children}</section>
      <Footer />
    </div>
  );
}
