import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminLayout from "../../LayoutWrapper/AdminLayout";
import "../../CSS/Blog/index.css";
import BlogImg from "../../Images/Blog/blogImg.jpg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Blog = () => {
  const { t } = useTranslation();

  return (
    <AdminLayout>
      <section className="page-title-wrapper pt-5">
        <Container>
          <Row>
            <Col>
              <h1 className="pageTitle mb-4">{t("page_title_blog")}</h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="blogBanner my-md-5 my-4">
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col lg={7}>
              <div className="blogBannerImg bgImg h-100"></div>
            </Col>
            <Col lg={5} className="mt-lg-0 mt-4">
              <div className="content-Wrapper ps-2 pe-5">
                <h3 className="mb-4">{t("dummy_title")}</h3>
                <p className="paragraph">{t("dummy_subPara")}</p>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="blog-contentBox-wrapper">
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col lg={4}>
              <div className="blog-box-wrapper mb-lg-0 mb-5">
                <div className="blog-image-wrapper">
                  <Link to="">
                    <img src={BlogImg} alt="" />
                  </Link>
                </div>
                <div className="blog-details-wrapper">
                  <Link to="">
                    <h3 className="blog-title mt-3 mb-2">{t("dummy_title")}</h3>
                  </Link>{" "}
                  <p className="paragraph blog-description">
                    {t("dummy_subPara")}
                  </p>
                  <p className="paragraph blog-publishDate">{t("blog_date")}</p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="blog-box-wrapper mb-lg-0 mb-5">
                <div className="blog-image-wrapper">
                  <Link to="">
                    <img src={BlogImg} alt="" />
                  </Link>
                </div>
                <div className="blog-details-wrapper">
                  <Link to="">
                    <h3 className="blog-title mt-3 mb-2">{t("dummy_title")}</h3>
                  </Link>
                  <p className="paragraph blog-description">
                    {t("dummy_subPara")}
                  </p>
                  <p className="paragraph blog-publishDate">{t("blog_date")}</p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="blog-box-wrapper mb-lg-0 mb-5">
                <div className="blog-image-wrapper">
                  <Link to="">
                    <img src={BlogImg} alt="" />
                  </Link>
                </div>
                <div className="blog-details-wrapper">
                  <Link to="">
                    <h3 className="blog-title mt-3 mb-2">{t("dummy_title")}</h3>
                  </Link>{" "}
                  <p className="paragraph blog-description">
                    {t("dummy_subPara")}
                  </p>
                  <p className="paragraph blog-publishDate">{t("blog_date")}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center mt-3">
            <Col lg={4}>
              <div className="blog-box-wrapper mb-lg-0 mb-5">
                <div className="blog-image-wrapper">
                  <Link to="">
                    <img src={BlogImg} alt="" />
                  </Link>
                </div>
                <div className="blog-details-wrapper">
                  <Link to="">
                    <h3 className="blog-title mt-3 mb-2">{t("dummy_title")}</h3>
                  </Link>{" "}
                  <p className="paragraph blog-description">
                    {t("dummy_subPara")}
                  </p>
                  <p className="paragraph blog-publishDate">{t("blog_date")}</p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="blog-box-wrapper mb-lg-0 mb-5">
                <div className="blog-image-wrapper">
                  <Link to="">
                    <img src={BlogImg} alt="" />
                  </Link>
                </div>
                <div className="blog-details-wrapper">
                  <Link to="">
                    <h3 className="blog-title mt-3 mb-2">{t("dummy_title")}</h3>
                  </Link>
                  <p className="paragraph blog-description">
                    {t("dummy_subPara")}
                  </p>
                  <p className="paragraph blog-publishDate">{t("blog_date")}</p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="blog-box-wrapper mb-lg-0 mb-5">
                <div className="blog-image-wrapper">
                  <Link to="">
                    <img src={BlogImg} alt="" />
                  </Link>
                </div>
                <div className="blog-details-wrapper">
                  <Link to="">
                    <h3 className="blog-title mt-3 mb-2">{t("dummy_title")}</h3>
                  </Link>{" "}
                  <p className="paragraph blog-description">
                    {t("dummy_subPara")}
                  </p>
                  <p className="paragraph blog-publishDate">{t("blog_date")}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center mt-3">
            <Col lg={4}>
              <div className="blog-box-wrapper mb-lg-0 mb-5">
                <div className="blog-image-wrapper">
                  <Link to="">
                    <img src={BlogImg} alt="" />
                  </Link>
                </div>
                <div className="blog-details-wrapper">
                  <Link to="">
                    <h3 className="blog-title mt-3 mb-2">{t("dummy_title")}</h3>
                  </Link>{" "}
                  <p className="paragraph blog-description">
                    {t("dummy_subPara")}
                  </p>
                  <p className="paragraph blog-publishDate">{t("blog_date")}</p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="blog-box-wrapper mb-lg-0 mb-5">
                <div className="blog-image-wrapper">
                  <Link to="">
                    <img src={BlogImg} alt="" />
                  </Link>
                </div>
                <div className="blog-details-wrapper">
                  <Link to="">
                    <h3 className="blog-title mt-3 mb-2">{t("dummy_title")}</h3>
                  </Link>{" "}
                  <p className="paragraph blog-description">
                    {t("dummy_subPara")}
                  </p>
                  <p className="paragraph blog-publishDate">{t("blog_date")}</p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="blog-box-wrapper mb-lg-0 mb-5">
                <div className="blog-image-wrapper">
                  <Link to="">
                    <img src={BlogImg} alt="" />
                  </Link>
                </div>
                <div className="blog-details-wrapper">
                  <Link to="">
                    <h3 className="blog-title mt-3 mb-2">{t("dummy_title")}</h3>
                  </Link>{" "}
                  <p className="paragraph blog-description">
                    {t("dummy_subPara")}
                  </p>
                  <p className="paragraph blog-publishDate">{t("blog_date")}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center mt-3 mb-5">
            <Col lg={4}>
              <div className="blog-box-wrapper mb-lg-0 mb-5">
                <div className="blog-image-wrapper">
                  <Link to="">
                    <img src={BlogImg} alt="" />
                  </Link>
                </div>
                <div className="blog-details-wrapper">
                  <Link to="">
                    <h3 className="blog-title mt-3 mb-2">{t("dummy_title")}</h3>
                  </Link>{" "}
                  <p className="paragraph blog-description">
                    {t("dummy_subPara")}
                  </p>
                  <p className="paragraph blog-publishDate">{t("blog_date")}</p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="blog-box-wrapper mb-lg-0 mb-5">
                <div className="blog-image-wrapper">
                  <Link to="">
                    <img src={BlogImg} alt="" />
                  </Link>
                </div>
                <div className="blog-details-wrapper">
                  <Link to="">
                    <h3 className="blog-title mt-3 mb-2">{t("dummy_title")}</h3>
                  </Link>{" "}
                  <p className="paragraph blog-description">
                    {t("dummy_subPara")}
                  </p>
                  <p className="paragraph blog-publishDate">{t("blog_date")}</p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="blog-box-wrapper mb-lg-0 mb-5">
                <div className="blog-image-wrapper">
                  <Link to="">
                    <img src={BlogImg} alt="" />
                  </Link>
                </div>
                <div className="blog-details-wrapper">
                  <Link to="">
                    <h3 className="blog-title mt-3 mb-2">{t("dummy_title")}</h3>
                  </Link>{" "}
                  <p className="paragraph blog-description">
                    {t("dummy_subPara")}
                  </p>
                  <p className="paragraph blog-publishDate">{t("blog_date")}</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </AdminLayout>
  );
};

export default Blog;
