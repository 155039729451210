import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import AdminLayout from "../../LayoutWrapper/AdminLayout";
import "../../CSS/UserSignup/index.css";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import { postApihandler } from "../../ApiHandler";
import { useForm } from "react-hook-form";

const DeleteAccountPermanent = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const [validated, setValidated] = useState(false);
  const { register, handleSubmit, reset, setValue, watch } = useForm();

  const SubmitLogins = async (value) => {
    let result = await postApihandler("/addEmailRequestForDelete", value);
    console.log("response - ", result);
    if (result.status == 200) {
      swal({
        icon: "success",
        text: "Request Send Successfully",
      });
      history("/");
    } else {
      swal({
        icon: "error",
        title: "Please Try Again",
        text: result.error.response.data.message,
      });
    }
  };
  return (
    <AdminLayout>
      <div className="outer-div-delete-account">
       
        <h5 className="text-center mt-4 signupTitle">
        Delete Your Account
        </h5>
       
        <section className="signupFormWrapper d-flex justify-content-center mb-5  flex-lg-row flex-column">
          <div className="form-Data-Wrapper form-wrapper-column-2 py-4 px-5">
           
            <div className="form-field-wrapper">
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit(SubmitLogins)}
              >
                <Row className="mb-4">
                  <Form.Group as={Col} controlId="validationCustomUsername">
                    <InputGroup hasValidation>
                      <Form.Control
                        type="email"
                        placeholder={t("form_email_text")}
                        aria-describedby="inputGroupPrepend"
                        required
                        {...register("email")}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please fill a email.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>

                <Form.Group className="text-center mb-4">
                  <Button type="submit">Send</Button>
                </Form.Group>
              </Form>
            </div>
          </div>
        </section>
      </div>
    </AdminLayout>
  );
};

export default DeleteAccountPermanent;
