import React from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../LayoutWrapper/AdminLayout";

const CookiesPolicy = () => {
  const { t } = useTranslation();

  return (
    <AdminLayout>
      <section className="cookies-wrapper py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="page-title text-center">
                {t("page_title_cookies_policy")}
              </h1>

              <div className="comfort-body-content py-md-5 py-3">
               
                <p className="page-para">Welcome to Comfort-Trip.com. This Cookies Policy explains what cookies are, how we use them, and how you can manage your cookie preferences when using our website.</p>
                <h2 className="point-title mt-4 mb-3">1. What Are Cookies?</h2>
                <p className="page-para">Cookies are small text files stored on your device by the websites you visit. They are used to make websites work more efficiently, to provide analytics, and to remember your preferences. Cookies are essential for enhancing your browsing experience and enabling certain website functionalities.</p>
                <h2 className="point-title mt-4 mb-3">2. Types of Cookies We Use</h2>
                <p className="page-para">We use several types of cookies on Comfort-Trip.com:</p>
                <p className="page-para">Essential Cookies: These are necessary for the website to function properly and cannot be switched off. They help with basic functions like navigation, secure access, and form submissions.</p>
                <p className="page-para">Performance and Analytics Cookies: These cookies help us understand how visitors interact with our website by collecting and reporting information anonymously. They allow us to track usage patterns and improve site performance.</p>
                <p className="page-para">Functional Cookies: These cookies remember your preferences, such as language selection or region, to provide a more personalized experience.</p>
                <p className="page-para">Advertising and Marketing Cookies: We may use these cookies to deliver relevant advertisements and promotional content. They help us understand the effectiveness of our marketing campaigns.</p>
                <h2 className="point-title mt-4 mb-3">3. How We Use Cookies</h2>
                <p className="page-para">Comfort-Trip.com uses cookies to:</p>
                <p className="page-para">Facilitate website functionality and provide a seamless user experience.</p>
                <p className="page-para">Analyze site usage and performance to improve our content and services.</p>
                <p className="page-para">Remember user preferences, such as login information, to enhance your visit.</p>
                <p className="page-para">Offer personalized recommendations and targeted advertisements.</p>
                <h2 className="point-title mt-4 mb-3">4. Third-Party Cookies</h2>
                <p className="page-para">We may use third-party service providers, such as Google Analytics, to help us understand how users interact with our site. These providers may set their own cookies on our website, which are governed by their respective privacy policies.</p>
                <h2 className="point-title mt-4 mb-3">5. Managing Cookies</h2>
                <p className="page-para">You can control your cookie preferences through your browser settings. Most browsers allow you to block or delete cookies. However, please note that disabling cookies may impact your user experience and limit certain features on Comfort-Trip.com.</p>
                <h2 className="point-title mt-4 mb-3">6. Updates to Our Cookies Policy</h2>
                <p className="page-para">We may occasionally update our Cookies Policy to reflect changes in our practices. Any updates will be posted on this page, so please review it periodically.</p>
                <h2 className="point-title mt-4 mb-3">7. Contact Us</h2>
                <p className="page-para">If you have questions about our Cookies Policy, please contact us at <span className="font-bold">support@comfort-trip.com</span>.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AdminLayout>
  );
};

export default CookiesPolicy;
