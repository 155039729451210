import React, { useEffect } from "react";
import Logo from "../../Images/Header/logo.png";
import EN from "../../Images/Header/EN-Icon.png";
import { Link } from "react-router-dom";
import LangDropdown from "../../Components/Language-Dropdown";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import PersonIcon from "@mui/icons-material/Person";
import userIcon from "../../Images/SignupForm/user-icon.png";
import { Popover, Typography } from "@mui/material";
function Header() {
  const { t } = useTranslation();
  const Token = localStorage.getItem("accessToken");
  console.log("token==>", Token);
  const handleClick = (e) => {
    i18next.changeLanguage(e.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const logout = async()=>{
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    window.location.reload();
  }
  return (
    <nav className="navbar navbar-expand-lg bg-dark text-white">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src={Logo} alt="" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarScroll">
          <ul className="navbar-nav mx-auto my-2 my-lg-0 navbar-nav-scroll">
            <li className="nav-item">
              <Link
                className="nav-link active text-white"
                aria-current="page"
                to="/"
              >
                {t("menu_1")}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/about-us">
                {t("menu_2")}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/user-signup">
                {t("menu_3")}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/driver-signup">
                {t("menu_4")}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/safety">
                {t("menu_5")}
              </Link>
            </li>
            <li className="nav-item">
              {/* <Link className="nav-link text-white" to="#">
                
                <span className="text-white">EN</span>
              </Link> */}
              <img src={EN} alt="" />
              <LangDropdown onChange={(e) => handleClick(e)} />
            </li>
            <li className="nav-item header-btns">
              {Token ? (
                <>
                  {" "}
                  <div onClick={handleClick1}>
                    <img style={{ width: "80%" }} src={userIcon} alt="" />
                  </div>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Link to="/delete-account"><Typography sx={{ p: 2 }}>Delete Account</Typography></Link>
                    <div onClick={logout}><Typography sx={{ p: 2 }}>Log Out</Typography></div>
                  </Popover>
                </>
              ) : (
                <>
                  <Link to="/user-login">
                    <button className="header-btns bg-none-btn">
                      {" "}
                      {t("navbar_btn1")}
                    </button>
                  </Link>
                  <Link to="/user-signup">
                    <button className="header-btns bg-btn">
                      {" "}
                      {t("navbar_btn2")}
                    </button>
                  </Link>
                </>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
