import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import AdminLayout from "../../LayoutWrapper/AdminLayout";
import ForgetPassword from "../../Images/SignupForm/update-password.png";
import "../../CSS/UserSignup/index.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { putApihandler } from "../../ApiHandler";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";

const UpdatePasswordUser = () => {
    let { email } = useParams();
  
  const { t } = useTranslation();
  const {register,handleSubmit,reset}=useForm();
  const history = useNavigate();

  const [validated, setValidated] = useState(false);

  const onSubmit = async (value) => {
    // console.log("event==>",value);
    // const email = localStorage.getItem("Email");
    // console.log("email====>",email);
    const res = await putApihandler(`/resetPassword/${email}`,value);
    console.log("res-->",res);
    if(res.status === 200){
      swal({
        icon: "success",
        text: "Your password will be changend Succesfully",
      });  
      reset();
      history("/user-login")
    }
    setValidated(true);
  };

  return (
    <AdminLayout>
      <section className="frgt-pswd-wrapper  py-5 mb-5">
        <div className="forget-img-wrapper text-center">
          <img src={ForgetPassword} alt=""/>
        </div>
        {/* <p className="paragraph text-center">{t("reset_password_tagline")}</p> */}
        <div className="form-Data-Wrapper form-wrapper-column-2 py-4 px-5">
          <div className="form-field-wrapper">
            <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
              <Row className="mb-4">
                <Form.Group as={Col} controlId="validationCustomUsername">
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      placeholder={t("form_password_text")}
                      aria-describedby="inputGroupPrepend"
                      required
                      {...register("password")}
                    />
                    
                    <Form.Control.Feedback type="invalid">
                      Please fill a Password.
                    </Form.Control.Feedback>
                  </InputGroup>
                  <InputGroup hasValidation className="mt-3">
                    <Form.Control
                      type="text"
                      placeholder={t("form_confirm_password_text")}
                      aria-describedby="inputGroupPrepend"
                      required
                      {...register("confirmPassword")}
                    />
                    
                    <Form.Control.Feedback type="invalid">
                      Please fill a Confirm Password.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Form.Group className="text-center mb-4">
                <Button type="submit">{t("form_sbmtBtn")}</Button>
              </Form.Group>
            </Form>
          </div>
        </div>
      </section>
    </AdminLayout>
  );
};

export default UpdatePasswordUser;
