import AdminLayout from "../../LayoutWrapper/AdminLayout";
import { useTranslation } from "react-i18next";
import React from "react";

const TermsConditions = () => {
  const { t } = useTranslation();
  return (
    <AdminLayout>
      <section className="privacy-wrapper py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="page-title text-center" id="page-heading">
                {t("page_title_terms_conditions")}
              </h1>

              <div className="comfort-body-content py-md-5 py-3">
                <h2 className="point-title mt-md-4 mt-2 mb-3">
                  {t("term_condition_section_title1")}
                </h2>
                <p className="page-para">
                  {" "}
                  {t("term_condition_section_para1")}
                </p>
                <h2 className="point-title mt-4 mb-3">
                  {t("term_condition_section_title2")}
                </h2>
                <p className="page-para">
                  {t("term_condition_section_para21")}
                  <br />
                  {t("term_condition_section_para22")}
                </p>
                <h2 className="point-title mt-4 mb-3">
                  {t("term_condition_section_title3")}
                </h2>
                <p className="page-para">
                  {t("term_condition_section_para31")}
                  <br />
                  {t("term_condition_section_para32")} <br />
                  {t("term_condition_section_para33")}
                </p>
                <h2 className="point-title mt-4 mb-3">
                  {" "}
                  {t("term_condition_section_title4")}
                </h2>
                <p className="page-para">
                  {t("term_condition_section_para41")} <br />{" "}
                  {t("term_condition_section_para42")}
                </p>
                <h2 className="point-title mt-4 mb-3">
                  {" "}
                  {t("term_condition_section_title5")}
                </h2>
                <p className="page-para">
                  {t("term_condition_section_para51")}
                  <br /> {t("term_condition_section_para52")}
                  <br />
                  {t("term_condition_section_para53")}
                  <br />
                  {t("term_condition_section_para54")}
                </p>
                <h2 className="point-title mt-4 mb-3">
                  {t("term_condition_section_title6")}
                </h2>
                <p className="page-para">
                  {t("term_condition_section_para61")}
                  <br />
                  {t("term_condition_section_para62")}
                </p>
                <h2 className="point-title mt-4 mb-3">
                  {t("term_condition_section_title7")}
                </h2>
                <p className="page-para">
                  {t("term_condition_section_para71")}
                  <br />
                  {t("term_condition_section_para72")}
                </p>
                <h2 className="point-title mt-4 mb-3">
                  {t("term_condition_section_title8")}
                </h2>
                <p className="page-para">{t("term_condition_section_para8")}</p>
                <h2 className="point-title mt-4 mb-3">
                  {t("term_condition_section_title9")}
                </h2>
                <p className="page-para">{t("term_condition_section_para9")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AdminLayout>
  );
};

export default TermsConditions;
