import React from "react";

const LangDropdown = (props) => {
  return (
    <div className="ms-2">
      <select
        onChange={props.onChange}
        className="bg-none border-none text-white"
      >
        <option>Select</option>
        <option value={"en"}>EN</option>
        <option value={"uzb"}>UZB</option>
        <option value={"russ"}>RUSS</option>
      </select>
    </div>
  );
};

export default LangDropdown;
